import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { CustomerActivityService } from '@customer-activity/customer-activity.service';
import { ListingActivityConstants } from '@listings/constants/listing-activity-constants';
import { SetListingActivityRequest } from '@listings/models/api/set-listing-activity-request';
import { ListingHelperService } from '@listings/services/listing-helper.service';
import { ListingApiService } from '@listings/store/services/listing-api.service';
import * as listingActivityActions from '../actions/listing-activity.actions';
import { ListingsStoreService } from '../services/listings-store.service';
import { SavedSearchStoreService } from '@saved-search/store/services/saved-search-store.service';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { ListingsActivityInfo } from '@listings/models/listing/listings-activity-info';

@Injectable()
export class ListingActivityEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly listingApiService: ListingApiService,
        private readonly customerActivityService: CustomerActivityService,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly savedSearchStoreService: SavedSearchStoreService,
        private readonly userStoreService: UserStoreService,
    ) { }

    public readonly setListingsActivity$ = createEffect(() => this.actions$.pipe(
        ofType(listingActivityActions.setListingsActivity),
        concatLatestFrom(() => [
            this.listingsStoreService.getListings(),
            this.listingsStoreService.getCustomerListings(),
            this.listingsStoreService.resentlyViewedListings$,
            this.userStoreService.getAgent()
        ]),
        mergeMap(([{ request, customerId }, listings, customerListings, resentlyViewedListings, agent]) => {
            const listingsWithoutRemoved = request.listingCandidates
                .filter(x =>
                    listings[x.id] != null && !listings[x.id].isDeleted ||
                    !(customerListings.find(l => l.hashCode === x.hashCode)?.isDeleted || resentlyViewedListings.find(l => l.hashCode === x.hashCode)?.isDeleted)
                );

            if (listingsWithoutRemoved.some(listing => listing.isNewMatch)) {
                const pickedActivityRequest: SetListingActivityRequest = {
                    activity: ListingActivityConstants.PickListed,
                    listingCandidates: listingsWithoutRemoved.filter(listing => listing.isNewMatch),
                    isReactionRemoved: false,
                    notifyRequired: false
                };

                return this.listingApiService.setListingsActivity(pickedActivityRequest, customerId, agent.id)
                    .pipe(mergeMap(() => this.listingApiService.setListingsActivity({ ...request, listingCandidates: listingsWithoutRemoved }, customerId, agent.id)));
            }

            return this.listingApiService.setListingsActivity({ ...request, listingCandidates: listingsWithoutRemoved }, customerId, agent.id);
        })
    ));

    public readonly setListingsActivitySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(listingActivityActions.setListingsActivitySuccess),
                concatLatestFrom(() => [this.listingsStoreService.baseListings$]),
                switchMap(([{ request }, listings]) => {
                    const activity = ListingHelperService.mapActivityToListingActivityType(request.activity.id);

                    if (activity == null || request.isReactionRemoved) {
                        return EMPTY;
                    }

                    const listingCategory = request.listingCandidates.reduce(
                        (result, { id }) => listings[id] == null ? result : [...result, { listingId: id, category: listings[id].category }],
                        [] as { listingId: string, category: string }[]
                    );

                    return [listingActivityActions.addListingSessionActivity({ listingActivityType: activity, listingCategory })];
                })
            )
    );

    public readonly addListingSessionActivity$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(listingActivityActions.addListingSessionActivity),
                concatLatestFrom(() => this.savedSearchStoreService.activeSavedSearchId$),
                switchMap(([{ listingActivityType, listingCategory }, activeSavedSearchId]) => {
                    return this.customerActivityService.addListingSessionActivity(listingActivityType, listingCategory, activeSavedSearchId);
                })
            ),
        { dispatch: false });

    public readonly loadListingsActivitiesRequested$ = createEffect(() => this.actions$.pipe(
        ofType(listingActivityActions.loadListingsActivitiesRequested),
        concatLatestFrom(() => this.listingsStoreService.areListingsActivitiesLoaded$),
        switchMap(([{ hashCodes, isForce, shouldSetLoading, shouldSetLoaded }, areListingsActivitiesLoaded]) => {
            return areListingsActivitiesLoaded && !isForce ? EMPTY : [listingActivityActions.loadListingsActivities({ hashCodes, shouldSetLoading, shouldSetLoaded })];
        })
    ));

    public readonly loadListingsActivities$ = createEffect(() => this.actions$.pipe(
        ofType(listingActivityActions.loadListingsActivities),
        mergeMap(({ hashCodes, shouldSetLoaded }) => this.listingApiService.loadListingsActivities(hashCodes, shouldSetLoaded))
    ));

    public readonly loadListingsActivitiesSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(listingActivityActions.loadListingsActivitiesSuccess),
        concatLatestFrom(() => this.listingsStoreService.listingsActivities$),
        map(([{ activities }, listingsActivities]) => {
            const updatedActivities = Object.entries({ ...listingsActivities, ...activities }).reduce(
                (acc, [hashcode, activitiesInfo]) => ({ ...acc, [hashcode]: (activities[+hashcode] ?? activitiesInfo) }),
                {} as Record<number, ListingsActivityInfo>
            );

            return listingActivityActions.setListingsActivities({ activities: updatedActivities });
        })
    ));
}