<div class="mat-slider-wrapper" #sliderWrapper>
  <div class="mat-slider-track-wrapper">
    <div class="mat-slider-track-background" [ngStyle]="_getTrackBackgroundStyles()"></div>
    <div class="mat-slider-track-fill" [ngStyle]="_getTrackFillStyles()"></div>
  </div>
  <div class="mat-slider-ticks-container" [ngStyle]="_getTicksContainerStyles()">
    <div class="mat-slider-ticks" [ngStyle]="_getTicksStyles()"></div>
  </div>
  <div class="mat-slider-thumb-container" [ngStyle]="_getThumbContainerStyles()">
    <div class="mat-slider-focus-ring"></div>
    <div class="mat-slider-thumb"></div>
    <div class="mat-slider-thumb-label">
      <span class="mat-slider-thumb-label-text">{{displayValue}}</span>
    </div>
  </div>
</div>
