import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, mergeMap } from 'rxjs';

import * as listingActivityActions from '@listings/store/actions/listing-activity.actions';
import * as listingActions from '@listings/store/actions/listings.actions';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import { ListingEventsApiService } from '../../services/listing-events-api.service';
import { ListingActivityType } from '@customer-activity/enums/listing-activity-type.enum';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { ShareType } from 'app/graphql/graphql';
import { SavedSearchStoreService } from '@saved-search/store/services/saved-search-store.service';

@Injectable()
export class ListingsEventsEffects {

    constructor(private readonly actions$: Actions,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly userStoreService: UserStoreService,
        private readonly listingEventsService: ListingEventsApiService,
        private readonly savedSearchStoreService: SavedSearchStoreService,
    ) { }

    public readonly activityEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listingActivityActions.addListingSessionActivity),
            concatLatestFrom(() => [
                this.listingsStoreService.getListings(),
                this.userStoreService.customerId$
            ]),
            mergeMap(([{ listingActivityType, listingCategory }, listings, customerId]) => {

                const selectedListingIds = listingCategory.map(l => l.listingId);

                const listingsIdentifiers = selectedListingIds.map(id => {
                    const listing = listings[id];

                    return { listingId: listing.hashCode };
                });

                switch (listingActivityType) {
                    case ListingActivityType.Viewed: {
                        return this.listingEventsService.trackViewed(listingsIdentifiers[0]);
                    }

                    case ListingActivityType.Liked: {
                        return this.listingEventsService.trackLiked(customerId, listingsIdentifiers);
                    }

                    case ListingActivityType.PickListed: {
                        return this.listingEventsService.trackPicked(customerId, listingsIdentifiers);
                    }

                    case ListingActivityType.Shared: {

                        return this.listingEventsService.trackListingShared({
                            listingIdentifiers: listingsIdentifiers,
                            shareType: ShareType.Url,
                        }, true);
                    }

                    default: return EMPTY;
                }

            })),
        { dispatch: false });

    public readonly searchEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listingActions.loadMarketListingsSuccess),
            concatLatestFrom(() => this.savedSearchStoreService.activeSavedSearchId$),
            mergeMap(([{ listings }, activeSavedSearchId]) => {

                const listingsIdentifiers = Object.values(listings).map(l => ({ listingId: l.hashCode }));

                return this.listingEventsService.trackSearchResults({
                    listingIdentifiers: listingsIdentifiers,
                    savedSearchDetails: activeSavedSearchId != null
                        ? { id: activeSavedSearchId }
                        : null
                });

            })),
        { dispatch: false });
}
